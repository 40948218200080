/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {changeEmailConfirmation, login, logout, validate} from '../core/_requests'
import {toAbsoluteUrl, KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useCookies} from 'react-cookie'
import {useQuery} from 'react-query'
import {Fade, Spinner} from 'react-bootstrap'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Email yang anda masukkan tidak valid').required('Email wajib diisi'),
  password: Yup.string()
    .min(8, 'Password harus terdiri dari minimal 8 karakter')
    .required('Password wajib diisi'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [isVerifLoading, setIsVerifLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<unknown>()
  const [success, setSuccess] = useState<string>()
  const [cookies, setCookies, removeCookies] = useCookies(['_bt'])
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [errorMsg, setErrorMsg] = useState('')

  // email validation
  useEffect(() => {
    const email = searchParams.get('email')
    if (email) {
      validate(email)
        .then((res) => {
          setSuccess('Email berhasil teraktivasi, silahkan login')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [searchParams.get('email')])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSuccess('')
      try {
        const {data} = await login(values.email, values.password)
        setLoading(false);
        setSuccess(data.message);
        
        // setCookies('_bt', data.data.access_token)
        // alert("https://persona.diawan.id" + '=' + encodeURIComponent(data.data.access_token) + " " + data.data.access_token);
        // const redirectLink = "https://persona.diawan.id" + '=' + encodeURIComponent(data.data.access_token) + " " + data.data.access_token;
        const url = "https://persona.diawan.id"
        // const redirectLink = "http://localhost:3002" + "?access_token=" + encodeURIComponent(data.data.access_token);
        const redirectLink = `${url}?extra=${encodeURIComponent(data.data.access_token)}`;
        window.location.href = redirectLink
        // window.location.href = 'https://persona.diawan.id'
      } catch (error: any) {
        setLoading(false)
        setHasErrors(true)
        setErrorMsg(error?.response?.data?.message)
      }
    },
  })

  const [emailVerification, setEmailVerification] = useState<string | null>()

  const {refetch} = useQuery(
    ['change-email-verification', emailVerification],
    () => changeEmailConfirmation(emailVerification),
    {
      enabled: false,
      onSuccess: (data) => {
        setLoading(false)
        setSuccess(data?.data?.message)
        setIsVerifLoading(false)
        refetchLogout()
      },
      onError: (error: any) => {
        setLoading(false)
        setIsVerifLoading(false)
        setHasErrors(error)
        if (error?.response?.status == 500) {
          setErrorMsg('Perubahan email gagal, silahkan coba lagi nanti')
        } else {
          setErrorMsg(error?.response?.data?.message)
        }
        setTimeout(() => {
          // window.location.href = 'http://localhost:3001'
          window.location.href = 'https://persona.diawan.id'
        }, 1000)
      },
    }
  )

  const {refetch: refetchLogout} = useQuery('logout', () => logout(cookies._bt), {
    enabled: false,
    onSuccess: (data) => {
      setCookies('_bt', null)
      removeCookies('_bt')
    },
  })

  useEffect(() => {
    if (searchParams.get('verify-changed-email') && cookies._bt) {
      setEmailVerification(searchParams.get('verify-changed-email'))
      setSuccess('')
      setHasErrors('')
      setIsVerifLoading(true)
      refetch()
    }
  }, [emailVerification])

  return (
    <>
      {isVerifLoading && (
        <Fade in>
          <div
            className='d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100 bg-white bg-opacity-50'
            style={{zIndex: 999999999}}
          >
            <Spinner
              animation='border'
              className='w-80px h-80px'
              variant='primary'
              style={{borderWidth: '10px'}}
            />
          </div>
        </Fade>
      )}
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='card card-custom card-flush bg-white'>
          {/* begin::body */}
          <div className='card-body'>
            {/* begin::Logo Title */}
            <div className='mb-10 text-center'>
              <img
                className='mb-3 w-md-50px w-40px'
                src={toAbsoluteUrl('/media/diawan/diawan-logo.png')}
                alt='diawan logo'
              />
              <h1 className='text-dark fw-bolder mb-3'>Masuk</h1>
              <div className='text-gray-500 fw-semibold fs-6'>Selamat Datang Kembali!</div>
            </div>
            {/* end::Logo Title */}

            {/* begin::Alert */}
            {success ? (
              <div className='alert alert-success d-flex align-items-center gap-3' role='alert'>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-2x svg-icon-success'
                />
                <div className='text-dark'>{success}</div>
              </div>
            ) : (
              ''
            )}
            {/* end::Alert */}

            {/* begin::Alert */}
            {hasErrors ? (
              <div className='alert alert-danger d-flex align-items-center gap-3' role='alert'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon svg-icon-2x svg-icon-danger'
                />
                <div className='text-dark'>{errorMsg}</div>
              </div>
            ) : (
              ''
            )}
            {/* end::Alert */}

            {/* begin::Login Input Field */}
            <div className='mb-5'>
              <div className='form-floating'>
                <input
                  id='email-input'
                  type='email'
                  placeholder='email@example.com'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                />
                <label htmlFor='email-input' className='form-label'>
                  Email
                </label>
              </div>
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-5'>
              <div className='form-floating'>
                <input
                  id='password-input'
                  type='password'
                  placeholder='password'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.password && formik.errors.password},
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                <label htmlFor='password-input' className='form-label'>
                  Password
                </label>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>

            <div className=''>
              <button
                className={`btn ${!formik.isValid ? 'btn-secondary' : 'btn-primary'} w-100`}
                type='submit'
                disabled={!formik.isValid}
              >
                {loading ? (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                ) : (
                  'Masuk'
                )}
              </button>
            </div>
            {/* end::Login Input Field */}
          </div>
          {/* end::Body */}

          {/* begin::Footer */}
          <div className='card-footer'>
            <div className='d-flex border-1 border-top pt-3 gap-4 text-decoration-underline text-primary fw-semibold fs-base'>
              <Link to='/registration'>Buat Akun</Link>
              <Link to='/forgot-password'>Lupa Kata Sandi</Link>
            </div>
          </div>
          {/* begin::Footer */}
        </div>
      </form>
    </>
  )
}
